import { ROUTES } from '@/shared/constants'
import { openToast } from '@/store/genericToast/actions'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useNavigate } from 'react-router-dom'

const useRedirectToModuleWithSelectedCustomer = () => {
  const { customersList } = useTypedSelector((state) => ({ ...state }))
  const overviewCellDispatch = useAppDispatch()
  const navigate = useNavigate()

  const redirectToModuleWithSelectedCustomer = (to: ROUTES, companyId: string) => {
    const customer = customersList.find((c) => c.id === companyId)
    if (!customer) {
      overviewCellDispatch(
        openToast({
          children: `Can not find customer ${companyId}, redirect is canceled`,
          feSeverity: 'error',
        })
      )
      return
    }
    overviewCellDispatch(setSelectedCustomer(customer))
    navigate(`${to}/${companyId}`)
  }
  return redirectToModuleWithSelectedCustomer
}

export default useRedirectToModuleWithSelectedCustomer
