// =================================================================================================
// IMPORTS
// =================================================================================================

import { FC, Fragment } from 'react'
import { Row } from '@tanstack/react-table'
import styled from '@emotion/styled'
import { ChannelsPane } from './channels-pane'
import { dataColumnRecord } from '../data'
import { COLORS } from '@skf-internal/ui-components-react'
import { ColumnId, DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { getRowBackgroundColor } from '@/modules/devices-sensors/utils/tableUtils'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DataRow: FC<{
  row: Row<DevicesSensorsTableDeviceRowData>
  odd: boolean
}> = ({ row, odd }) => {
  const { devicesAndSensorsSelectedItemsData, setDevicesAndSensorsSelectedItemsData } = useDevicesAndSensorsContext()

  const { device: selectedDevice } = devicesAndSensorsSelectedItemsData

  if (!selectedDevice) return

  const deviceData = row.original
  const deviceId = deviceData.id
  const isDeviceSelected = selectedDevice && deviceId === selectedDevice?.id
  const handleRowClick = () =>
    setDevicesAndSensorsSelectedItemsData({
      ...devicesAndSensorsSelectedItemsData,
      device: deviceData,
      channel: undefined,
    })

  return (
    <Fragment key={row.index}>
      <StyledRow data-index={row.index} onClick={handleRowClick} odd={odd} isDeviceSelected={isDeviceSelected}>
        {row.getVisibleCells().map((cell) => {
          const columnId: ColumnId = cell.column.id! as ColumnId
          const ColDefComponent = () => dataColumnRecord[columnId](cell)
          return <ColDefComponent key={cell.id} />
        })}
      </StyledRow>
      {row.getIsExpanded() && (
        <ChannelsPane
          device={deviceData}
          key={`${row.index}-channels`}
          channels={row.original['channels']}
          isDeviceSelected={isDeviceSelected}
        />
      )}
    </Fragment>
  )
}

// =================================================================================================
// STYLES
// =================================================================================================

const StyledRow = styled.tr<{ odd: boolean; isDeviceSelected: boolean }>`
  background-color: ${(props) => getRowBackgroundColor(props.odd, props.isDeviceSelected)};

  &:hover {
    cursor: pointer;
    ${(props) => !props.isDeviceSelected && `background-color: ${COLORS.primary200};`}
  }
`
