// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'
import { ToolTipOverviewDashboard } from '../tooltip'
import { OverviewDataRow } from '@/models/overview/types'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { ROUTES } from '@/shared/constants'
import {
  StyledOverviewAutomaticDiagnosticsColumn,
  StyledOverviewAutomaticDiagnosticsTooltip,
  StyledEmptySpaceHeader,
} from '@/modules/overview/styled'
import { dataTestId } from '@/tests/testid'
import useRedirectToModuleWithSelectedCustomer from '@/modules/overview/hooks/useRedirectToModuleWithSelectedCustomer'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'

// =================================================================================================
// UTILS
// ===============

const tooltipText: Record<string, string> = {
  'auto-diagnostic': 'Percentage of automatically diagnosed assets',
}

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateAutoDiagnosticsColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('autoDiagnostics', {
    id: 'autoDiagnostics',
    header: 'Automatic Diagnostics',
    /* v8 ignore next 1 */
    cell: ({ getValue }) => <div>{getValue()}</div>,
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableAutoDiagnosticsColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableAutoDiagnosticsHeaderColumn: FC<{
  header: Header<OverviewDataRow, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<OverviewDataRow> header={header} centerValue={true} withSorting={false}>
    <StyledEmptySpaceHeader className="sub-header" />
  </TableHeaderColumn>
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================
interface OverAllTableAutoDiagnosticsColumnCell {
  value: string
  companyId: string
}
const OverAllTableAutoDiagnosticsColumnCell: FC<OverAllTableAutoDiagnosticsColumnCell> = ({ value, companyId }) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  return (
    <td>
      <StyledOverviewAutomaticDiagnosticsColumn>
        <div>
          <ToolTipOverviewDashboard message={tooltipText['auto-diagnostic']}>
            <a
              data-testid={dataTestId.overviewAutoDiagnosticsColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.AUTOMATIC_DIAGNOSTICS, companyId)}
              className="cell-value"
            >
              <StyledOverviewAutomaticDiagnosticsTooltip threshold={parseFloat(value)} />
              {value}
            </a>
          </ToolTipOverviewDashboard>
        </div>
      </StyledOverviewAutomaticDiagnosticsColumn>
    </td>
  )
}

export default OverAllTableAutoDiagnosticsColumnCell
