// =================================================================================================
// IMPORTS
// =================================================================================================

import { ComponentColumnCell } from '@/common/table/base-components/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import StatusValueTooltip from '@/modules/devices-sensors/components/tooltip'
import { filterRegistry } from '@/shared/components/GenericTableFilter/constants'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { ColumnHelper, Header, Row } from '@tanstack/react-table'
import { FC, ReactNode } from 'react'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PX = 200

export const generateStatusColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    sortingFn: (
      rowA: Row<DevicesSensorsTableDeviceRowData>,
      rowB: Row<DevicesSensorsTableDeviceRowData>,
      columnId: string
    ): number => {
      const order: DevicesSensorsTableDeviceRowData['status'][] = ['normal', 'notNormal']

      const aIdx = order.indexOf(rowA.getValue<DevicesSensorsTableDeviceRowData['status']>(columnId))
      const bIdx = order.indexOf(rowB.getValue<DevicesSensorsTableDeviceRowData['status']>(columnId))

      if (aIdx > bIdx) {
        return 1
      } else if (aIdx === bIdx) {
        return 0
      } else {
        return -1
      }
    },
    enableColumnFilter: true,
    filterFn: filterRegistry.status.filterFn,
    cell: ({ getValue }) => {
      return <StatusValueTooltip value={getValue()} />
    },
    meta: {
      filterType: 'device_status',
    },
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={true}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableStatusColumnCell: FC<{ children: ReactNode }> = ({ children }) => (
  <ComponentColumnCell columnWidth={COLUMN_WIDTH_IN_PX} centerValue={true}>
    {children}
  </ComponentColumnCell>
)
