export const sortRAtableWithAssetStatus = (statusA: string, statusB: string) => {
  const statusOrder: { [key: string]: number } = {
    severe: 1,
    unacceptable: 2,
    unsatisfactory: 3,
    acceptable: 4,
    normal: 5,
  }
  return statusOrder[statusA.toLowerCase()] - statusOrder[statusB.toLowerCase()]
}
