import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { SPACINGS, Flexbox } from '@skf-internal/ui-components-react'
import { GlobalFilters, RecommendedActionStatusType, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { useDashboardContext } from '@/contexts/dashboard'
import RecommendedActionSelect from './RecommendedActionSelect'
import StatusSelect from './StatusSelect'
import FaultTypeSelect from './FaultTypeSelect'
import {
  severities as allAssetStatusValues,
  workOrderStatuses as allRaStatusValues,
} from '@/modules/dashboard/utils/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'

const GlobalFiltering: FC = () => {
  const { faultTypeSelectOptions, dashboardFilters, setDashboardFilters, setWidgetErrors } = useDashboardContext()

  const allFaultTypeValues = useMemo(
    () => faultTypeSelectOptions.map((option) => option.faultType),
    [faultTypeSelectOptions]
  )
  const updateFilters = (newFilters: SelectFilterType) => {
    setDashboardFilters(newFilters)
    setWidgetErrors([])
  }

  useDeepCompareEffect(() => {
    updateFilters({
      raStatus: allRaStatusValues as RecommendedActionStatusType[],
      faultType: allFaultTypeValues,
      assetStatus: allAssetStatusValues,
    })
  }, [faultTypeSelectOptions])

  const handleAssetStatusChange = (assetStatus: SelectFilterType['assetStatus']) => {
    updateFilters({ ...dashboardFilters, assetStatus })
  }

  const handleRaStatusChange = (raStatus: SelectFilterType['raStatus']) => {
    if (raStatus.includes('all') || raStatus.length === 0) {
      raStatus = allRaStatusValues as RecommendedActionStatusType[]
    }
    updateFilters({ ...dashboardFilters, raStatus })
  }

  const handleFaultTypeChange = (faultType: GlobalFilters['faultType']) => {
    if (faultType.includes('all') || faultType.length === 0) {
      faultType = allFaultTypeValues
    }
    updateFilters({ ...dashboardFilters, faultType })
  }

  return (
    <Flexbox
      css={css`
        gap: ${SPACINGS.md};
        min-height: 75px;
      `}
      feAlignItems="center"
      data-tets
    >
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <StatusSelect value={dashboardFilters.assetStatus} onChange={handleAssetStatusChange} />
      </Flexbox>
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <RecommendedActionSelect value={dashboardFilters.raStatus} onChange={handleRaStatusChange} />
      </Flexbox>
      <Flexbox feJustifyContent="center" feAlignItems="start">
        <FaultTypeSelect value={dashboardFilters.faultType} onChange={handleFaultTypeChange} />
      </Flexbox>
    </Flexbox>
  )
}
export default GlobalFiltering
