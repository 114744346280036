import styled from '@emotion/styled'
import { COLORS, Icon } from '@skf-internal/ui-components-react'

export const StyledTableHeaderColumn = styled.th<{ cursor: string; width: string }>`
  cursor: ${({ cursor }) => cursor};
  width: ${({ width }) => width};
`

export const StyledTableHeaderColumnContainer = styled.div<{ flexContentJustification: string }>`
  display: flex;
  justify-content: ${({ flexContentJustification }) => flexContentJustification};
  align-items: center;
`

export const StyledTabledHeaderColumnIconContainer = styled.div`
  display: flex;
  gap: inherit;
  align-items: inherit;
`

export const StyledTabledHeaderColumnIcon = styled(Icon)`
  color: ${COLORS.gray500};
`
