import { AnalysisReasonStringType } from '@/models/analysisBacklog/types'
import ReasonIndicatorFilter from '@/modules/analysis-backlog/asset-table/reasonIndicator/filter'
import { ReasonIndicatorFilterProps } from '@/modules/analysis-backlog/asset-table/reasonIndicator/types'
import { FilterContentProps } from '@/shared/components/GenericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { COLORS, Flexbox } from '@skf-internal/ui-components-react'
import { FC, useCallback, useState } from 'react'

/**
 * A component for filtering assets based on analysis reasons.

 * @param {FilterContentProps<AnalysisReasonStringType[]>} props - The component props.
 * @property {AnalysisReasonStringType[]} props.initialValue - The initial selected analysis reasons.
 * @property {(newFilterValue: AnalysisReasonStringType[] | undefined) => void} props.onFilterValueChange - Callback function to handle changes in selected reasons.
 * @returns {JSX.Element} The rendered AnalysisReasonContent component.
 */
const AnalysisReasonContent: FC<FilterContentProps<AnalysisReasonStringType[]>> = ({
  initialValue,
  onFilterValueChange,
}) => {
  const [filteredAnalysisReasons, setFilteredAnalysisReasons] = useState<AnalysisReasonStringType[]>(initialValue || [])

  const findAnalysisReasonValue = useCallback(
    (value: AnalysisReasonStringType): boolean => filteredAnalysisReasons.includes(value),
    [filteredAnalysisReasons]
  )

  const handleOnChange = useCallback(
    (reason: AnalysisReasonStringType, checked: boolean) => {
      let newFilterValue: AnalysisReasonStringType[] | undefined = undefined

      if (checked) {
        newFilterValue = [...filteredAnalysisReasons, reason]
        setFilteredAnalysisReasons(newFilterValue)
      } else {
        newFilterValue = filteredAnalysisReasons.filter((v) => v !== reason)
        setFilteredAnalysisReasons(newFilterValue)
      }

      if (newFilterValue.length === 0) {
        newFilterValue = undefined
      }

      onFilterValueChange(newFilterValue)
    },
    [filteredAnalysisReasons, onFilterValueChange]
  )

  const reasonFiltersLeft = [
    { reasonSeverity: 'alarm', reasonText: 'Band', reasonType: 'band_alarm' },
    { reasonIcon: { iconColor: 'red', iconName: 'danger' }, reasonText: 'AI alarm', reasonType: 'ai' },
    { reasonSeverity: 'alert', reasonText: 'DiagX', reasonType: 'diagnostics_alert' },
    { reasonSeverity: 'alarm', reasonText: 'Overall', reasonType: 'overall_alarm' },
    { reasonIcon: { iconColor: 'purple', iconName: 'sensor' }, reasonText: 'Device fault', reasonType: 'device_fault' },
    {
      reasonIcon: { iconColor: 'white', iconName: 'unknownOutline' },
      reasonText: 'Sensor data missing',
      reasonType: 'no_sensor_data',
      customStyle: { backgroundColor: COLORS.gray600, color: COLORS.white },
    },
  ] as ReasonIndicatorFilterProps[]

  const reasonFiltersRight = [
    { reasonSeverity: 'alert', reasonText: 'Band', reasonType: 'band_alert' },
    { reasonSeverity: 'alarm', reasonText: 'DiagX', reasonType: 'diagnostics_alarm' },
    { reasonIcon: { iconColor: 'red', iconName: 'danger' }, reasonText: 'Protean alarm', reasonType: 'protean_alarm' },
    { reasonSeverity: 'alert', reasonText: 'Overall', reasonType: 'overall_alert' },
    {
      reasonIcon: { iconColor: 'blue', iconName: 'email' },
      reasonText: 'Feedback',
      reasonType: 'feedback',
      withBadge: true,
    },
  ] as ReasonIndicatorFilterProps[]

  return (
    <Flexbox feFlexDirection="row" feGap="xxs" data-testid={dataTestId.analysisReasonContent}>
      {[reasonFiltersLeft, reasonFiltersRight].map((filters, index) => (
        <Flexbox key={index} feFlexDirection="column" feGap="xs">
          {filters.map(({ reasonIcon, reasonSeverity, reasonText, reasonType, customStyle, withBadge }) => (
            <ReasonIndicatorFilter
              key={reasonType}
              reasonIcon={reasonIcon}
              reasonSeverity={reasonSeverity}
              reasonText={reasonText}
              checked={findAnalysisReasonValue(reasonType)}
              onChange={handleOnChange}
              reasonType={reasonType}
              customStyle={customStyle}
              withBadge={withBadge}
            />
          ))}
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export default AnalysisReasonContent
