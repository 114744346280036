import { OrphanNoteRow } from '@/models/orphanNotes/types'
import AcknowledgedRowCell from '@/modules/orphan-notes/components/cells/AcknowledgedRowCell'
import ActionCell from '@/modules/orphan-notes/components/cells/ActionCell'
import NoteCreationDateColumnHeader from '@/modules/orphan-notes/components/cells/NoteCreationDateColumnHeader'
import NoteCreationDateCell from '@/modules/orphan-notes/components/cells/NoteCreationDateCell'
import NoteCell from '@/modules/orphan-notes/components/cells/NoteCell'
import { createColumnHelper, Row } from '@tanstack/react-table'
import { filterRegistry } from '@/shared/components/GenericTableFilter/constants'

const columnHelper = createColumnHelper<OrphanNoteRow>()

const acknowledgmentColumnSortingFunction = (
  rowA: Row<OrphanNoteRow>,
  rowB: Row<OrphanNoteRow>,
  columnId: string
): number => {
  const aAck = rowA.getValue<OrphanNoteRow['acknowledged']>(columnId)
  const bAck = rowB.getValue<OrphanNoteRow['acknowledged']>(columnId)
  if (aAck === true && bAck === false) {
    return 1
  } else if (aAck === false && bAck === true) {
    return -1
  } else {
    return 0
  }
}

const orphanNotesColumns = [
  columnHelper.accessor('acknowledged', {
    id: 'acknowledged',
    header: undefined,
    cell: AcknowledgedRowCell,
    minSize: 20,
    size: 20,
    enableColumnFilter: false,
    enableSorting: true,
    sortingFn: acknowledgmentColumnSortingFunction,
  }),
  columnHelper.accessor('composedAt', {
    id: 'composedAt',
    header: NoteCreationDateColumnHeader,
    enableSorting: true,
    sortingFn: 'datetime',
    filterFn: filterRegistry.text.filterFn,
    cell: NoteCreationDateCell,
    size: 35,
    meta: {
      filterType: 'date',
    },
  }),
  columnHelper.display({
    id: 'note',
    header: 'Note',
    cell: NoteCell,
  }),
  columnHelper.display({
    id: 'action',
    header: undefined,
    cell: ActionCell,
    minSize: 50,
    size: 60,
    maxSize: 50,
  }),
]

export { orphanNotesColumns }
