// =================================================================================================
// IMPORTS
// =================================================================================================
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useRef } from 'react'
import { generateAnalysisBacklogColumnDefinition } from './columns/analysis-backlog-column'
import { generateAutoDiagnosticsColumnDefinition } from './columns/auto-diagnostics-column'
import { generateDevicesSensorsColumnDefinition } from './columns/devices-sensors-management-column'
import { generateUnresolvedRecommendationsColumnDefinition } from './columns/unresolved-recommendations-column'
import { TableData } from './data'
// import { generateComplianceColumnDefinition } from './columns/compliance-column'
import { useOverviewContext } from '@/contexts/overview'
import { OverviewDataRow } from '@/models/overview/types'
import { StyledOverviewTable } from '@/modules/overview/styled'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import ColGroup from './colGroup'
import { generateCompanyColumnDefinition } from './columns/company-column'
import { TableHeader } from './tableHeader'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { generateActionsDefinition } from '@/modules/overview/components/overview-table/columns/actions-column'
import ScrollableInset from '@/shared/components/TableComponents/ScrollableInset'
import TablePadding from '@/shared/components/TableComponents/TablePadding'

// =================================================================================================
// TYPES
// =================================================================================================

export type ColumnId =
  | 'company'
  // | 'compliance'
  | 'analysisBacklog'
  | 'unresolvedRecommendations'
  | 'deviceAndSensors'
  | 'autoDiagnostics'
  | 'actions'

// =================================================================================================
// COLUMN DEFINITIONS
// =================================================================================================

const columnHelper = createColumnHelper<OverviewDataRow>()
export const columnDefinitions = [
  generateCompanyColumnDefinition(columnHelper),
  // generateComplianceColumnDefinition(columnHelper),
  generateAnalysisBacklogColumnDefinition(columnHelper),
  generateUnresolvedRecommendationsColumnDefinition(columnHelper),
  generateDevicesSensorsColumnDefinition(columnHelper),
  generateAutoDiagnosticsColumnDefinition(columnHelper),
  generateActionsDefinition(columnHelper),
]

// =================================================================================================
// COMPONENT
// =================================================================================================

const OverviewTable = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { overviewData } = useOverviewContext()
  const overViewDispatch = useAppDispatch()
  const { fetchDataStatus } = useFetchDataContext()

  const table = useReactTable({
    columns: columnDefinitions,
    data: overviewData,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  })

  const { getVirtualItems, getTotalSize } = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: table.getRowModel().rows.length,
    overscan: 10,
    estimateSize: () => 46,
  })

  const tableHeaderGroups = table.getHeaderGroups()

  const paddingTop = getVirtualItems().length > 0 ? getVirtualItems()[0].start || 0 : 0
  const paddingBottom =
    getVirtualItems().length > 0 ? getTotalSize() - (getVirtualItems()[getVirtualItems().length - 1].end || 0) : 0

  useDeepCompareEffect(() => {
    if (table.getRowCount() > 0 || (table.getRowCount() === 0 && fetchDataStatus !== 'loading')) {
      overViewDispatch(stopLoading())
    }
  }, [table, fetchDataStatus])

  return (
    <>
      <ScrollableInset ref={tableContainerRef}>
        <StyledOverviewTable aria-label="Overview table">
          <ColGroup />
          <TableHeader tableHeaderGroups={tableHeaderGroups} />
          <tbody>
            <TablePadding height={paddingTop} />
            <TableData tableRowModel={table.getRowModel()} items={getVirtualItems()} />
            <TablePadding height={paddingBottom} />
          </tbody>
        </StyledOverviewTable>
      </ScrollableInset>
    </>
  )
}

export default OverviewTable
