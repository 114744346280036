// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, ReactNode } from 'react'
import { IconButton, IconName, SIZES, css } from '@skf-internal/ui-components-react'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { ComponentColumnCell } from '@/common/table/base-components/column-cell'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PX = 100

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateExpandRowColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.display({
    id: 'expandRow',
    size: 30,
    cell: ({ row }) => {
      const isRowExpanded = row.getIsExpanded()
      const icon: IconName = isRowExpanded ? 'chevronUp' : 'chevronDown'
      const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        row.toggleExpanded()
      }

      return (
        <IconButton
          aria-label="expand"
          as="button"
          feColor="brand"
          feSize="sm"
          feIcon={icon}
          onClick={(event) => handleExpandClick(event)}
          css={css`
            height: ${SIZES.s32};
            width: ${SIZES.s32};

            display: flex;
            align-items: center;
            justify-content: center;
          `}
        />
      )
    },
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableExpandRowColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableExpandRowHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={true}
    withSorting={false}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableExpandRowColumnCell: FC<{ children: ReactNode }> = ({ children }) => (
  <ComponentColumnCell columnWidth={COLUMN_WIDTH_IN_PX} centerValue={true}>
    {children}
  </ComponentColumnCell>
)
