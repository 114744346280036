import { ReasonIndicatorData, AnalysisReasonType, ReasonConfigReturn } from '@/models/analysisBacklog/types'
import ReasonIndicator from '@/modules/analysis-backlog/asset-table/reasonIndicator'
import { COLORS } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { trackAIStrikerClick } from '@/app/datadog-config'

const reasonConfigMap: {
  [K in AnalysisReasonType]: (severity?: string, externalURL?: string) => ReasonConfigReturn
} = {
  band: (severity = '') => ({
    reasonText: `Band ${severity}`,
  }),
  diagnostics: (severity = '') => ({
    reasonText: `DiagX ${severity}`,
  }),
  protean: () => ({
    reasonText: 'Protean alarm',
    reasonIcon: {
      iconColor: 'red',
      iconName: 'danger',
    },
  }),
  ai: (_, externalURL = '') => ({
    reasonText: 'AI alarm',
    reasonIcon: {
      iconColor: 'red',
      iconName: 'danger',
    },
    withRedirect: true,
    redirectUrl: externalURL,
    onClick: trackAIStrikerClick,
  }),
  overall: (severity = '') => ({
    reasonText: `Overall ${severity}`,
  }),
  feedback: () => ({
    reasonText: 'Feedback',
    reasonIcon: {
      iconColor: 'blue',
      iconName: 'email',
    },
    withBadge: true,
  }),
  device_fault: () => ({
    reasonText: 'Device fault',
    reasonIcon: {
      iconColor: 'purple',
      iconName: 'sensor',
    },
  }),
  no_sensor_data: () => ({
    reasonText: 'No sensor data',
    reasonIcon: {
      iconColor: 'white',
      iconName: 'unknownOutline',
    },
    customStyle: {
      backgroundColor: COLORS.gray600,
    },
  }),
  no_data_24h: () => ({
    reasonText: 'No data 24h',
    reasonIcon: {
      iconColor: 'purple',
      iconName: 'sensor',
    },
  }),
}

interface AnalysisReasonProps {
  reason: ReasonIndicatorData
}

const AnalysisReason: FC<AnalysisReasonProps> = ({ reason }) => {
  const { type, count, severity, externalURL } = reason

  const config = reasonConfigMap[type]?.(severity, externalURL)

  return config && <ReasonIndicator reasonCount={count} reasonSeverity={severity} {...config} />
}

export default AnalysisReason
