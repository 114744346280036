import { css } from '@skf-internal/ui-components-react'

const NoteCreationDateColumnHeader = () => {
  return (
    <div
      css={css`
        white-space: pre-wrap;
      `}
    >
      Note Creation Date
    </div>
  )
}

export default NoteCreationDateColumnHeader
