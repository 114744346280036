import AssetStatusCheckbox from '@/shared/components/GenericTableFilter/filterHelpComponents/AssetStatusCheckbox'
import { FilterContentProps } from '@/shared/components/GenericTableFilter/types'
import { ROUTES } from '@/shared/constants'
import { AssetStatus, AssetStatusString } from '@/shared/models/types'
import { getAssetStatusType } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * A component for filtering assets based on their status.

 * @param {FilterContentProps<AssetStatus[]>} props - The component props.
 * @property {AssetStatus[]} [props.initialValue] - The initial selected asset statuses.
 * @property {(newFilterValue: AssetStatus[] | undefined) => void} props.onFilterValueChange - Callback function to handle changes in selected statuses.
 * @returns {JSX.Element} The rendered AssetStatusFilterContent component.
 */
const AssetStatusFilterContent: FC<FilterContentProps<AssetStatus[]>> = ({ initialValue, onFilterValueChange }) => {
  const { pathname } = useLocation()

  const statusesToRender = useMemo<string[]>(() => {
    let result = Object.keys(AssetStatus)
    if (pathname.includes(ROUTES.UNRESOLVED_RECOMMENDATION)) {
      const openRecommendationsStatuses = ['acceptable', 'unacceptable', 'unsatisfactory', 'severe']
      result = result.filter((status) => openRecommendationsStatuses.includes(status))
    }
    return result
  }, [pathname])

  const [filteredStatus, setFilteredStatus] = useState<AssetStatus[]>(initialValue || [])

  const findStatusValue = useCallback(
    (value: AssetStatusString): boolean => filteredStatus.includes(getAssetStatusType(value)),
    [filteredStatus]
  )

  const handleOnChange = useCallback(
    (status: AssetStatusString, checked: boolean) => {
      let newFilterValue: AssetStatus[] | undefined = undefined

      if (checked) {
        newFilterValue = [...filteredStatus, AssetStatus[status]]
        setFilteredStatus(newFilterValue)
      } else {
        newFilterValue = filteredStatus.filter((v) => v !== AssetStatus[status])
        setFilteredStatus(newFilterValue)
      }

      if (newFilterValue.length === 0) {
        newFilterValue = undefined
      }

      onFilterValueChange(newFilterValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredStatus]
  )

  return (
    <Flexbox
      feJustifyContent="center"
      feFlexDirection="column"
      feGap="md"
      data-testid={dataTestId.assetStatusFilterContent}
    >
      {statusesToRender.map((status) => (
        <AssetStatusCheckbox
          onChange={handleOnChange}
          checked={findStatusValue(status as AssetStatusString)}
          status={status as AssetStatusString}
          key={status}
        />
      ))}
    </Flexbox>
  )
}

export default AssetStatusFilterContent
