import { css } from '@emotion/react'
import { AssetRow } from '@/models/analysisBacklog/types'
import { Row } from '@tanstack/react-table'
import { EmptyState } from '@skf-internal/ui-components-react'
import { useTypedSelector } from '@/store/store'
import { isArray } from 'lodash-es'
import { useLocation } from 'react-router-dom'

interface EmptyOrphanNotesTableStateProps {
  countRows: Row<AssetRow>[] | number
}

const EmptyOrphanNotesTableState: React.FC<EmptyOrphanNotesTableStateProps> = ({
  countRows,
}: EmptyOrphanNotesTableStateProps) => {
  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  const tableRows = isArray(countRows) ? countRows.length : countRows

  const { state } = useLocation()

  return (
    <>
      {tableRows === 0 && !isGlobalLoading && !state?.assetsData ? (
        <div
          css={css({
            width: '100%',
            height: 'calc(100% - 36px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <EmptyState
            feHeading={{
              children: 'No assets could be found',
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export default EmptyOrphanNotesTableState
