import { Select, SelectItem } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { StatusType, StatusSelectType } from '@/models/dashboard/globalFiltering/types'
import { severities as allStatuses } from '@/modules/dashboard/utils/constants'
import { dataTestId } from '@/tests/testid'
import { getEnumKeys } from '@/shared/utils'
import { AssetStatus } from '@/shared/models/types'

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Hide normal', value: 'hideNormal' },
  { label: 'Unsatisfactory, unacceptable, severe', value: 'unsatisfactoryUnacceptableSevere' },
  { label: 'Unsatisfactory, severe', value: 'unsatisfactorySevere' },
  { label: 'Severe', value: 'severe' },
] as const

type StatusOptionValue = (typeof statusOptions)[number]['value']

const getStatuses = (value: StatusOptionValue): StatusType[] => {
  switch (value) {
    case 'all':
      return allStatuses
    case 'hideNormal':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal'])
    case 'unsatisfactoryUnacceptableSevere':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable'])
    case 'unsatisfactorySevere':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable'])
    case 'severe':
      return getEnumKeys(AssetStatus, ['never-reported', 'normal', 'acceptable', 'unacceptable', 'unsatisfactory'])
    default:
      return []
  }
}

const mapStatusesToOption = (statuses: StatusType[]): StatusOptionValue => {
  switch (true) {
    case statuses.length === allStatuses.length && statuses.every((status, index) => status === allStatuses[index]):
      return 'all'

    case statuses.length === 4 &&
      statuses.every((status) => ['severe', 'unacceptable', 'unsatisfactory', 'acceptable'].includes(status)):
      return 'hideNormal'

    case statuses.length === 3 &&
      statuses.every((status) => ['severe', 'unacceptable', 'unsatisfactory'].includes(status)):
      return 'unsatisfactoryUnacceptableSevere'

    case statuses.length === 2 && statuses.every((status) => ['severe', 'unsatisfactory'].includes(status)):
      return 'unsatisfactorySevere'

    case statuses.length === 1 && statuses[0] === 'severe':
      return 'severe'

    default:
      return 'all'
  }
}
interface IStatusSelect {
  className?: string
  value: StatusSelectType[]
  onChange: (value: StatusType[]) => void
}

const StatusSelect: FC<IStatusSelect> = ({ className, value, onChange }) => {
  const options = useMemo<SelectItem<StatusOptionValue>[]>(
    () =>
      statusOptions.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    []
  )

  return (
    <Select<StatusOptionValue>
      data-testid={dataTestId.globalFilteringAssetStatusSelect}
      css={css`
        * {
          flex-wrap: wrap;
        }

        label {
          font-weight: 700;
        }

        span {
          font-weight: 400;
        }

        width: 300px;
      `}
      feSize="sm"
      className={className}
      multiple={false}
      value={mapStatusesToOption(value as StatusType[])}
      onChange={(selectedValue: StatusOptionValue) => onChange(getStatuses(selectedValue as StatusOptionValue))}
      feItems={options}
      feLabel="Asset status"
    />
  )
}
export default StatusSelect
