import { DeviceStatus } from '@/models/devicesAndSensors/types'
import { DeviceStatusIcon, DeviceStatusString } from '@/modules/devices-sensors/utils/constants'
import { FilterCheckboxProps } from '@/shared/components/GenericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { Checkbox, Flexbox, Icon } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DeviceStatusCheckbox: FC<FilterCheckboxProps<DeviceStatus>> = ({ status, checked, onChange }) => {
  const { icon, color } = DeviceStatusIcon[status]

  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <Checkbox
        name={status}
        checked={checked}
        feLabel={DeviceStatusString[status]}
        onChange={(_, checked) => onChange(status, checked)}
        data-testid={dataTestId.filterDeviceStatusCheckbox}
      />
      <Icon feIcon={icon} feColor={color} feSize="sm" />
    </Flexbox>
  )
}

export default DeviceStatusCheckbox
