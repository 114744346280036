// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, useId, useRef, useState } from 'react'
import { OverviewDataRow } from '@/models/overview/types'
import { ColumnDef } from '@/common/table/base-components/column-def'
// import { ActionsColumn } from '@/common/table/base-components/header-column'
import { ENV, ROUTES } from '@/shared/constants'
import {
  StyledEmptySpaceHeader,
  StyledPopover,
  StyledLink,
  StyledActionMenu,
  StyledActionColumnPopup,
  StyledActionMenuContainer,
} from '@/modules/overview/styled'
import { dataTestId } from '@/tests/testid'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { IconButton, useTooltip } from '@skf-internal/ui-components-react'
import useRedirectToModuleWithSelectedCustomer from '@/modules/overview/hooks/useRedirectToModuleWithSelectedCustomer'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { Customer } from '@/models/customer/types'

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateActionsDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor((row) => row.company, {
    id: 'actions',
    cell: ({ getValue }) => <div>{getValue()}</div>,
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableActionsColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableActionsHeaderColumn: FC<{
  header: Header<OverviewDataRow, unknown>
}> = ({ header }) =>
  //{/*Feature flag to allow only in Dev and QA env*/}
  !(import.meta.env.MODE === ENV.PROD || import.meta.env.MODE === ENV.STAGING) ? (
    <TableHeaderColumn header={header} centerValue={true} columnWidth={70} withContent={false}>
      <StyledEmptySpaceHeader className="sub-header" />
    </TableHeaderColumn>
  ) : (
    <div />
  )

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

type ActionMenuProps = {
  siteId: Customer['id']
}

export const ActionMenu: FC<ActionMenuProps> = (props) => (
  <StyledActionMenu data-testid={dataTestId.overviewActionColumn}>
    <Menu {...props} />
  </StyledActionMenu>
)

export type MenuItem = 'mark-as-normal' | 'report-status'

type MenuPopoverContext = ActionMenuProps & {
  closePopover: (v: boolean) => void
}

const PopoverContent: FC<MenuPopoverContext> = ({ siteId }) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()

  const handleCustomerDetailsClick = () => {
    redirectToModuleWithSelectedCustomer(ROUTES.SITE_DETAILS, siteId)
  }

  // const handleGenerateReportClick = () => {}

  return (
    <div>
      {/* <StyledLink
        as="button"
        type="button"
        feIcon={{ feIcon: 'paper', position: 'left' }}
        feStretch={true}
        onClick={() => {
          // handleGenerateReportClick()
        }}
      >
        Generate Report
      </StyledLink> */}
      <StyledLink
        as="button"
        type="button"
        feIcon={{ feIcon: 'info', position: 'left' }}
        feStretch={true}
        onClick={handleCustomerDetailsClick}
      >
        Site Details
      </StyledLink>
    </div>
  )
}
const Menu: FC<ActionMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const outsideClickRef = useRef<HTMLDivElement>(null)
  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      refs: [outsideClickRef],
    }
  )
  const [clickedRef] = useTooltip()

  const headingId = useId()

  return (
    <div>
      <StyledActionColumnPopup isHovered={isOpen} triggerElement={clickedRef.current}>
        <StyledPopover className="Popover" ref={outsideClickRef} aria-labelledby={headingId}>
          <PopoverContent {...props} closePopover={() => setIsOpen(false)} />
        </StyledPopover>
      </StyledActionColumnPopup>

      <IconButton
        aria-label="Actions"
        as="button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        ref={clickedRef}
        feIcon="kebabMore"
        feSize="md"
      />
    </div>
  )
}
interface OverAllTableActionsCell {
  companyId: string
}
const OverAllTableActionsCell: FC<OverAllTableActionsCell> = ({ companyId }) => {
  return (
    <td>
      <StyledActionMenuContainer feJustifyContent="center">
        {/*Feature flag to allow only in Dev and QA env*/}
        {!(import.meta.env.MODE === ENV.PROD || import.meta.env.MODE === ENV.STAGING) && (
          <ActionMenu siteId={companyId} />
        )}
      </StyledActionMenuContainer>
    </td>
  )
}

export default OverAllTableActionsCell
