import { StyledTablePaddingData } from '@/shared/components/TableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'

interface TablePaddingProps {
  height: number
}
/**
 * A table row component that provides padding to the table.

 * @param {number} height - The height of the padding in pixels.
 * @returns {JSX.Element} The rendered TablePadding component.
 */
const TablePadding: FC<TablePaddingProps> = ({ height }) => {
  return (
    height &&
    height > 0 && (
      <tr data-testid={dataTestId.tablePadding}>
        <StyledTablePaddingData height={height} />
      </tr>
    )
  )
}

export default TablePadding
