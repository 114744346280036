import { Card, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledReasonIndicator = styled(Card)<{ withRedirect: boolean }>`
  padding: ${SPACINGS.xs} ${SPACINGS.sm};
  gap: ${SPACINGS.sm};
  align-items: center;
  height: 2.375rem;
  font-size: 1rem;
  display: flex;
  cursor: ${({ withRedirect }) => (withRedirect ? 'pointer' : 'auto')};

  & > [data-comp='divider'] {
    margin-inline: -${SPACINGS.xxs};
  }

  & > .container {
    align-self: baseline;
  }
`
