import { Flexbox, Text, SPACINGS, css } from '@skf-internal/ui-components-react'
import { Row } from '@tanstack/react-table'
import { FC } from 'react'
import { OrphanNoteRow } from '@/models/orphanNotes/types'
import OrphanNoteData from './OrphanNoteData'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { getBooleanString } from '@/shared/utils'

export const OrphanNoteTableRow: FC<{ row: Row<OrphanNoteRow> }> = ({ row }) => {
  const {
    message,
    isInvestigatingAlert,
    isResolvedIDNoteSKF,
    engineHour,
    sap,
    alertType,
    IsSKFInvestigating,
    droplistLevel1to4SKF,
    droplistLevel5SKF,
    lastModifiedTimestamp,
  } = row.original

  return (
    <Flexbox
      feGap="xxl"
      feFlexDirection="column"
      css={css`
        padding-block: ${SPACINGS.md};
      `}
    >
      <Flexbox feFlexDirection="column" feGap="lg">
        <div
          css={css`
            display: inline-block;

            & > span:not(:last-of-type) {
              padding-right: ${SPACINGS.md};
            }
          `}
        >
          <span>
            <b>HAL</b>
          </span>
        </div>
        <Text>
          <b>Note: </b>
          {message}
        </Text>
      </Flexbox>

      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fit, 170px);
          gap: ${SPACINGS.md};
        `}
      >
        <OrphanNoteData
          name="Last modified date"
          value={lastModifiedTimestamp ? formatDate(lastModifiedTimestamp, DateFormats.AmericanDateTimeFormat) : '-'}
        />
        <OrphanNoteData name="Is Investigating Alert?" value={getBooleanString(isInvestigatingAlert)} />
        <OrphanNoteData name="Is Resolved ID SKF?" value={getBooleanString(isResolvedIDNoteSKF)} />
        <OrphanNoteData name="Engine Hour" value={engineHour.toString()} />
        <OrphanNoteData name="SAP" value={sap.toString()} />
        <OrphanNoteData name="Alert Type" value={alertType.toString()} />
        <OrphanNoteData name="Is SKF Investigating?" value={getBooleanString(IsSKFInvestigating)} />
        <OrphanNoteData name="Droplist Level 1-4 SKF" value={droplistLevel1to4SKF.toString()} />
        <OrphanNoteData name="Droplist Level 5 SKF" value={droplistLevel5SKF.toString()} />
      </div>
    </Flexbox>
  )
}
