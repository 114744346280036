export enum ENV {
  DEV = 'development',
  BUILD_DEV = 'development-deploy',
  QA = 'qa-deploy',
  STAGING = 'staging-deploy',
  PROD = 'prod-deploy',
}

export const ERR_CANCELED = 'ERR_CANCELED'

export enum ROUTES {
  HOME = '/',
  ANALYSIS_BACKLOG = '/analysis-backlog',
  ORPHAN_NOTES = '/orphan-notes',
  DASHBOARD = '/dashboard',
  OVERVIEW = '/overview',
  SITE_DETAILS = '/site-details',
  UNRESOLVED_RECOMMENDATION = '/unresolved-recommendation',
  REPORT_STATUS = '/report-status',
  DEVICES_AND_SENSORS = '/devices-sensors',
  AUTOMATIC_DIAGNOSTICS = '/automatic-diagnostics',
  LOG_OUT = '/auth/sign-out',
  TAB_ERROR = '/tab-error',
  ERROR = '/error',
  NOT_FOUND = '*',
}

export const DATE_REFRESHER_TIMEOUT = 60000 * 5 // 5 minutes

export enum Roundness {
  Sm = '0.125rem',
  Base = '0.25rem',
  Md = '0.375rem',
  Lg = '0.5rem',
}

export enum Boldness {
  Light = 300,
  Regular = 400,
  Normal = 500,
  Bold = 700,
}
