// =================================================================================================
// IMPORTS
// =================================================================================================

import { css } from '@emotion/react'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC, ReactNode } from 'react'

// =================================================================================================
// INTERFACE
// =================================================================================================

interface ColumnCellProps<T> {
  value: T
  columnWidth: number
  centerValue: boolean
}

// =================================================================================================
// COMPONENTS
// =================================================================================================

export function ColumnCell<T extends number | string>({ value, columnWidth, centerValue }: ColumnCellProps<T>) {
  return (
    <td
      css={css`
        width: ${columnWidth}px;
      `}
    >
      <Flexbox feJustifyContent={centerValue ? 'center' : 'start'}>{value}</Flexbox>
    </td>
  )
}

export const ComponentColumnCell: FC<{ children: ReactNode; columnWidth: number; centerValue: boolean }> = ({
  children,
  columnWidth,
  centerValue,
}) => {
  return (
    <td
      css={css`
        width: ${columnWidth}px;
      `}
    >
      <Flexbox feJustifyContent={centerValue ? 'center' : 'start'}>{children}</Flexbox>
    </td>
  )
}
