import { css } from '@emotion/react'
import { COLORS } from '@skf-internal/ui-components-react'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { FC, Fragment, useMemo, useRef, useState } from 'react'
import { useOrphanNotesContext } from '@/contexts/orphanNotes'
import { orphanNotesColumns } from '@/modules/orphan-notes/utils/tableUtils'
import { OrphanNotesHeader } from '@/modules/orphan-notes/components/header'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useAppDispatch } from '@/store/store'
import { stopLoading } from '@/store/loading/action'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import EmptyOrphanNotesTableState from '@/modules/orphan-notes/components/noData'
import useTableKeyboardNavigation from '@/shared/hooks/useTableKeyboardNavigation'
import { StyledOrphanNotesTable, StyledOrphanNotesTableRow } from '@/modules/orphan-notes/styled'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { OrphanNoteRow } from '@/models/orphanNotes/types'
import ScrollableInset from '@/shared/components/TableComponents/ScrollableInset'
import TablePadding from '@/shared/components/TableComponents/TablePadding'

const OrphanNotesTable: FC = () => {
  const { orphanNotes, showAcknowledged } = useOrphanNotesContext()
  const { fetchDataStatus } = useFetchDataContext()
  const orphanNotesDispatch = useAppDispatch()

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'acknowledged', desc: false },
    {
      id: 'lastModifiedDate',
      desc: true,
    },
  ])

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0)

  const orphanNoteRows = useMemo(() => {
    if (showAcknowledged) {
      return [...orphanNotes.unacknowledged, ...orphanNotes.acknowledged]
    }

    return orphanNotes.unacknowledged
  }, [orphanNotes, showAcknowledged])

  const table = useReactTable({
    data: orphanNoteRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    columns: orphanNotesColumns,
  })

  useDeepCompareEffect(() => {
    if (table.getRowCount() > 0 || (table.getRowCount() === 0 && fetchDataStatus !== 'loading')) {
      orphanNotesDispatch(stopLoading())
    }
  }, [table, fetchDataStatus])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()
  const { getVirtualItems, getTotalSize, measureElement } = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    overscan: 10,
    estimateSize: () => 46,
  })

  const virtualRows = getVirtualItems()

  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? getTotalSize() - (virtualRows[virtualRows.length - 1].end || 0) : 0

  const filteredRowCount = table.getFilteredRowModel().rows.length

  const { tbodyRef, handleKeyDown } = useTableKeyboardNavigation()
  return (
    <>
      <OrphanNotesHeader table={table} />
      {fetchDataStatus !== 'loading' && (
        <ScrollableInset ref={tableContainerRef}>
          <StyledOrphanNotesTable aria-label="Orphan notes table">
            <colgroup>
              {table.getAllColumns().map((column) => (
                <col
                  key={column.id}
                  css={css`
                    width: ${column.columnDef.size === undefined ? 'auto' : `${column.getSize()}px`};
                    min-width: ${column.columnDef.minSize === undefined ? 'unset' : `${column.columnDef.minSize}px`};
                    max-width: ${column.columnDef.maxSize === undefined ? 'unset' : `${column.columnDef.maxSize}px`};
                  `}
                />
              ))}
            </colgroup>
            <thead
              css={css`
                z-index: 1;
              `}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Fragment key={header.id}>
                      <TableHeaderColumn<OrphanNoteRow> header={header} withSorting={false} />
                    </Fragment>
                  ))}
                </tr>
              ))}
            </thead>
            {filteredRowCount > 0 && (
              <tbody ref={tbodyRef}>
                <TablePadding height={paddingTop} />
                {getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index]
                  const acknowledged = rows[virtualRow.index].original.acknowledged

                  return (
                    <StyledOrphanNotesTableRow
                      isOdd={acknowledged}
                      isSelected={row.index === selectedRowIndex}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      id={row.id}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        handleKeyDown(e, row)
                        setSelectedRowIndex(row.index + (e.key === 'ArrowUp' ? -1 : 1))
                      }}
                      onClick={() => {
                        setSelectedRowIndex(row.index)
                      }}
                      ref={measureElement}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            css={css`
                              border-bottom: 1px solid ${COLORS.gray400};
                            `}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </StyledOrphanNotesTableRow>
                  )
                })}
                <TablePadding height={paddingBottom} />
              </tbody>
            )}
          </StyledOrphanNotesTable>
          <EmptyOrphanNotesTableState countRows={filteredRowCount} />
        </ScrollableInset>
      )}
    </>
  )
}

export default OrphanNotesTable
