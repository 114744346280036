// =================================================================================================
// IMPORTS
// =================================================================================================

import { useCallback, useEffect, useRef } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { generateNameColumnDefinition } from './columns/name-column'
import { generateStatusColumnDefinition } from './columns/status-column'
import { generateLastCommunicationColumnDefinition } from './columns/last-communication-date'
import { generateExpandRowColumnDefinition } from './columns/expand-row-column'
import { ColGroup } from './col-group'
import { TableHeader } from './header'
import { TableData } from './data'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { getFirstDevice } from '@/modules/devices-sensors/utils/tableUtils'
import { generateNumberColumnDefinition } from '@/modules/devices-sensors/components/table/columns/number-column'
import { DevicesSensorsHeader } from '@/modules/devices-sensors/components/header/header'
import { dataTestId } from '@/tests/testid'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopLoading } from '@/store/loading/action'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useParams } from 'react-router-dom'
import { generateConnectedAssetColumnDefinition } from './columns/connected-assets-column'
import ScrollableInset from '@/shared/components/TableComponents/ScrollableInset'
import { StyledTable } from '@/shared/components/TableComponents/styled'

// =================================================================================================
// TYPES
// =================================================================================================

// =================================================================================================
// COLUMN DEFINITIONS
// =================================================================================================

const columnHelper = createColumnHelper<DevicesSensorsTableDeviceRowData>()
export const columnDefinitions = [
  generateNumberColumnDefinition(columnHelper),
  generateNameColumnDefinition(columnHelper),
  generateStatusColumnDefinition(columnHelper),
  generateLastCommunicationColumnDefinition(columnHelper),
  generateConnectedAssetColumnDefinition(columnHelper),
  generateExpandRowColumnDefinition(columnHelper),
]

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsTable = () => {
  const {
    devicesAndSensorsData: tableData,
    devicesAndSensorsSelectedItemsData,
    devicesAndSensorsTableData,
    setDevicesAndSensorsTableData,
    setDevicesAndSensorsSelectedItemsData,
  } = useDevicesAndSensorsContext()

  const { sid } = useParams()

  const { sorting = [], expanded = {}, columnFilters = [] } = devicesAndSensorsTableData

  const { device: deviceData } = devicesAndSensorsSelectedItemsData

  const devicesSensorsTableDispatch = useAppDispatch()

  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  const { fetchDataStatus } = useFetchDataContext()

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columns: columnDefinitions,
    data: tableData,
    state: { sorting, expanded, columnFilters },
    onSortingChange: (updater) =>
      setDevicesAndSensorsTableData({
        ...devicesAndSensorsTableData,
        sorting: updater instanceof Function ? updater(sorting) : updater,
      }),
    onExpandedChange: (updater) =>
      setDevicesAndSensorsTableData({
        ...devicesAndSensorsTableData,
        expanded: updater instanceof Function ? updater(expanded) : updater,
      }),
    onColumnFiltersChange: (updater) => {
      setDevicesAndSensorsTableData({
        ...devicesAndSensorsTableData,
        columnFilters: updater instanceof Function ? updater(columnFilters) : updater,
      })
    },
    // ,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    isMultiSortEvent: () => true,
  })

  const tableRows = table.getRowModel()

  const selectTableInitialData = useCallback(() => {
    const rows = tableRows.rows.map((v) => v.original)

    if (rows.length > 0) {
      setDevicesAndSensorsSelectedItemsData({ ...devicesAndSensorsSelectedItemsData, device: getFirstDevice(rows) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows.rows])

  useEffect(() => {
    selectTableInitialData()
  }, [selectTableInitialData])

  useEffect(() => {
    table.resetColumnFilters()
  }, [sid, table])

  useDeepCompareEffect(() => {
    if (
      (tableRows.rows.length > 0 &&
        fetchDataStatus === 'success' &&
        deviceData?.channels &&
        deviceData?.channels.length > 0) ||
      (tableRows.rows.length === 0 && fetchDataStatus !== 'loading')
    ) {
      devicesSensorsTableDispatch(stopLoading())
    }
  }, [tableRows, fetchDataStatus])

  const tableHeaderGroups = table.getHeaderGroups()

  const tableContainerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <DevicesSensorsHeader table={table} filters={columnFilters} />
      {!isGlobalLoading && (
        <ScrollableInset ref={tableContainerRef} bottomMargin="21rem">
          <StyledTable aria-label="Devices sensors table" data-testid={dataTestId.deviceSensorsTable}>
            <ColGroup />
            <TableHeader tableHeaderGroups={tableHeaderGroups} />
            <tbody>
              <TableData items={tableRows.rows} />
            </tbody>
          </StyledTable>
        </ScrollableInset>
      )}
    </>
  )
}
