// =================================================================================================
// IMPORTS
// =================================================================================================

import { OverAllTableAnalysisBacklogColumnDef } from '@/modules/overview/components/overview-table/columns/analysis-backlog-column'
// import { OverAllTableComplianceColumnDef } from './columns/compliance-column'
import { ColumnId, columnDefinitions } from './table'
import { OverAllTableAutoDiagnosticsColumnDef } from '@/modules/overview/components/overview-table/columns/auto-diagnostics-column'
import { OverAllTableCompanyColumnDef } from '@/modules/overview/components/overview-table/columns/company-column'
import { OverAllTableDevicesSensorsColumnDef } from '@/modules/overview/components/overview-table/columns/devices-sensors-management-column'
import { OverAllTableUnresolvedRecommendationsColumnDef } from '@/modules/overview/components/overview-table/columns/unresolved-recommendations-column'
import { OverAllTableActionsColumnDef } from '@/modules/overview/components/overview-table/columns/actions-column'

// =================================================================================================
// UTILS
// =================================================================================================

const colDefRecord: Record<ColumnId, JSX.Element> = {
  company: <OverAllTableCompanyColumnDef />,
  // compliance: <OverAllTableComplianceColumnDef />,
  analysisBacklog: <OverAllTableAnalysisBacklogColumnDef />,
  unresolvedRecommendations: <OverAllTableUnresolvedRecommendationsColumnDef />,
  deviceAndSensors: <OverAllTableDevicesSensorsColumnDef />,
  autoDiagnostics: <OverAllTableAutoDiagnosticsColumnDef />,
  actions: <OverAllTableActionsColumnDef />,
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const ColGroup = () => {
  return (
    <colgroup>
      {columnDefinitions.map((column) => {
        const columnId: ColumnId = column.id! as ColumnId
        const ColDefComponent = () => colDefRecord[columnId]
        return <ColDefComponent key={column.id} />
      })}
    </colgroup>
  )
}

export default ColGroup
