import { getRowBackgroundColor } from '@/modules/devices-sensors/utils/tableUtils'
import {
  COLORS,
  Divider,
  DIVIDER_COLORS,
  Flexbox,
  Grid,
  SIZES,
  SPACINGS,
  styled,
  Text,
} from '@skf-internal/ui-components-react'

export const ViewFlexbox = styled(Flexbox)`
  flex-grow: 1;
`

export const ViewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.lg};
  padding: 0 0 0 1rem;
  margin: 2rem 0 2rem 1.25rem;
`

export const TableCard = styled(ViewCard)`
  width: 60%;
`

export const SideMenuCard = styled(ViewCard)`
  width: 40%;
  border-left: 1px solid lightgrey;
  margin-top: 2.75rem;
  overflow-y: auto;
  height: calc(100vh - 12rem);
`

export const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const StyledDevicesSensorsTableSensorRow = styled.tr<{ odd: boolean; selected: boolean }>`
  background-color: ${(props) => getRowBackgroundColor(props.odd, props.selected)};

  &:hover {
    cursor: pointer;
    ${(props) => !props.selected && `background-color: ${COLORS.primary200};`}
  }
`

export const RowDivider = styled.tr`
  height: 1.4px !important;
  border-top: ${SIZES.s02} solid ${COLORS.gray300};
`
export const TableRow = styled.tr`
  border-top: 1px solid ${COLORS.gray400};
  border-bottom: 1px solid ${COLORS.gray400};
`
export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`
export const TableData = styled.td`
  padding: 0 !important;
`

export const StyledImage = styled.img`
  width: 10rem;
`

export const StyledDivider = styled(Divider)`
  width: 100%;
  border: 1px solid;
  border-bottom-color: ${DIVIDER_COLORS.primary};
  opacity: 0.4;
`

export const StyledDataGrid = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 0 !important;
  row-gap: ${SPACINGS.md} !important;
`

export const StyledDeviceModel = styled(Text)`
  width: fit-content;
  white-space: nowrap;
`

export const StyledDevicesAndSensorsTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
    user-select: none;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`
