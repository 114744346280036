import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useUnresolvedRecommendationsContext } from '@/contexts/unresolvedRecommendations'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import {
  StyledUnresolvedRecommendationTable,
  StyledUnresolvedRecommendationTableRow,
} from '@/modules/unresolved-recommendation/styled'
import ScrollableInset from '@/shared/components/TableComponents/ScrollableInset'
import TablePadding from '@/shared/components/TableComponents/TablePadding'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useTableKeyboardNavigation from '@/shared/hooks/useTableKeyboardNavigation'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { EmptyState } from '@skf-internal/ui-components-react'
import { Table, flexRender } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { FC, Fragment, useEffect, useRef, useState } from 'react'

interface RecommendationTableProps {
  table: Table<RecommendedAction>
  tableScrollOffset: number | undefined
}

const RecommendationTable: FC<RecommendationTableProps> = ({ table, tableScrollOffset }: RecommendationTableProps) => {
  const tableRows = table.getRowModel().rows
  const tableColumns = table.getAllColumns()
  const tableHeaderGroups = table.getHeaderGroups()
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0)

  const unresolvedRecommendationDispatch = useAppDispatch()

  const { fetchDataStatus } = useFetchDataContext()

  const {
    globalLoading: { isGlobalLoading },
  } = useTypedSelector((state) => ({ ...state }))

  const { getVirtualItems, getTotalSize, measureElement, scrollToOffset, scrollToIndex, scrollOffset } = useVirtualizer(
    {
      getScrollElement: () => tableContainerRef.current,
      count: tableRows.length,
      overscan: 10,
      estimateSize: () => 46,
    }
  )
  const { setTableScrollPosition } = useUnresolvedRecommendationsContext()

  const virtualRows = getVirtualItems()
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? getTotalSize() - (virtualRows[virtualRows.length - 1].end || 0) : 0

  useEffect(() => {
    setTableScrollPosition(scrollOffset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOffset])

  useEffect(() => {
    !tableScrollOffset && tableRows.length > 0 && scrollToIndex(0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows])

  useEffect(() => {
    if (tableScrollOffset) {
      queueMicrotask(() => {
        scrollToOffset(tableScrollOffset, { behavior: 'smooth' })
      })
      setTableScrollPosition(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableScrollOffset, tableRows])

  useDeepCompareEffect(() => {
    if (tableRows.length > 0 || (tableRows.length === 0 && fetchDataStatus !== 'loading')) {
      unresolvedRecommendationDispatch(stopLoading())
    }
  }, [tableRows, fetchDataStatus])

  const { tbodyRef, handleKeyDown } = useTableKeyboardNavigation()

  return (
    !isGlobalLoading && (
      <>
        <ScrollableInset ref={tableContainerRef}>
          <StyledUnresolvedRecommendationTable
            aria-label="Unresolved Recommendation table"
            data-testid={dataTestId.unresolvedRecommendations}
          >
            <colgroup>
              {tableColumns.map((column) => (
                <col
                  key={column.id}
                  css={css`
                    width: ${column.columnDef.size === undefined ? 'auto' : `${column.getSize()}px`};
                    min-width: ${column.columnDef.minSize === undefined ? 'unset' : `${column.columnDef.minSize}px`};
                    max-width: ${column.columnDef.maxSize === undefined ? 'unset' : `${column.columnDef.maxSize}px`};
                  `}
                ></col>
              ))}
            </colgroup>
            <thead
              css={css`
                z-index: 1000;
              `}
            >
              {tableHeaderGroups.map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Fragment key={header.id}>
                      <TableHeaderColumn<RecommendedAction> header={header} />
                    </Fragment>
                  ))}
                </tr>
              ))}
            </thead>
            {tableRows.length > 0 && (
              <tbody ref={tbodyRef}>
                <TablePadding height={paddingTop} />
                {getVirtualItems().map((virtualRow) => {
                  const row = tableRows[virtualRow.index]
                  const odd = !!(virtualRow.index % 2)

                  return (
                    <StyledUnresolvedRecommendationTableRow
                      isOdd={odd}
                      isSelected={row.index === selectedRowIndex}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      id={row.id}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        handleKeyDown(e, row)
                        setSelectedRowIndex(row.index + (e.key === 'ArrowUp' ? -1 : 1))
                      }}
                      onClick={() => {
                        setSelectedRowIndex(row.index)
                      }}
                      ref={measureElement}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      })}
                    </StyledUnresolvedRecommendationTableRow>
                  )
                })}
                <TablePadding height={paddingBottom} />
              </tbody>
            )}
          </StyledUnresolvedRecommendationTable>

          {tableRows.length == 0 && fetchDataStatus == 'success' && (
            <div
              css={css({
                width: '100%',
                height: 'calc(100% - 36px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <EmptyState
                feHeading={{
                  children: 'No assets could be found',
                }}
              />
            </div>
          )}
        </ScrollableInset>
      </>
    )
  )
}

export default RecommendationTable
