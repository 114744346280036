import { COLORS, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledOrphanNotesRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  margin: 2rem ${SPACINGS.lg} 0;
  padding: 0 2rem;
`

export const StyledOrphanNotesTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
    user-select: none;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`
export const StyledOrphanNotesTableRow = styled.tr<{ isOdd: boolean; isSelected: boolean }>`
  width: 100%;
  height: 46px;
  background-color: ${({ isOdd, isSelected }) =>
    isSelected ? COLORS.primary300 : isOdd ? COLORS.gray050 : 'transparent'};

  &:hover {
    cursor: pointer;
    ${({ isSelected }) => !isSelected && `background-color: ${COLORS.primary200};`}
  }

  &:focus-visible {
    outline: none;
  }
`
