/* eslint-disable no-case-declarations */
import { Fault, FaultsFormState, RecommendedAction } from '@/models/reportStatus/faultsFormStateTypes'
import {
  ADD_EVIDENCE_UPLOADED_IMAGE,
  ADD_NEW_FAULT,
  REMOVE_EVIDENCE_UPLOADED_IMAGE,
  RESET_FORM,
  UPDATE_EVIDENCE_IMAGE_DATA,
  UPDATE_FAULT,
  UPDATE_RECOMMENDED_ACTION,
} from '@/modules/report-status/reducer/actions.types'
import { FaultsFormAction } from '@/modules/report-status/reducer/types'
import {
  buildFormErrorStruct,
  getEntityId,
  isFaultFormValid,
  removeKeysRecursively,
  revalidateFaultFormErrors,
  updateEntity,
  validateFault,
  validateRecommendedAction,
} from '@/modules/report-status/utils/formUtils'
import { findAndDelete, isEqual } from '@/shared/utils'

export type FaultsFormReducer = (state: FaultsFormState, action: FaultsFormAction) => FaultsFormState

const faultsFormReducer: FaultsFormReducer = (state: FaultsFormState, action: FaultsFormAction) => {
  switch (action.type) {
    case ADD_NEW_FAULT:
      return {
        ...state,
        faults: [...state.faults, { ...action.payload.newFault }],
        isDirty: true,
        isSubmitting: false,
        lastCollectionDate: action.payload.lastCollectionDate ?? state.lastCollectionDate,
        isValid: false,
        errors: validateFault(action.payload.newFault, structuredClone(state.errors)),
      }
    case RESET_FORM:
      return {
        faults: structuredClone(state.initialStateFaults),
        initialStateFaults: structuredClone(state.initialStateFaults),
        isSubmitting: false,
        isDirty: false,
        lastCollectionDate: state.lastCollectionDate,
        errors: revalidateFaultFormErrors(
          state.initialStateFaults,
          buildFormErrorStruct(structuredClone(state.initialStateFaults))
        ),
        isValid: true,
      }

    case UPDATE_FAULT:
      const copiedState = structuredClone(state)
      const foundFaultData = findAndDelete<Fault>(
        copiedState.faults,
        (fault) => getEntityId(fault) === action.payload.id
      )
      if (foundFaultData) {
        const updatedFaultData = updateEntity<Fault>(foundFaultData.foundItem, action.payload)

        if (updatedFaultData.hasChanges) {
          copiedState.faults.splice(foundFaultData.foundItemIndex, 0, updatedFaultData.updated)

          copiedState.isDirty = !isEqual(
            removeKeysRecursively(copiedState.faults, ['state', 'faultId', 'evidences']),
            removeKeysRecursively(state.initialStateFaults, ['state', 'faultId', 'evidences'])
          )
        } else {
          copiedState.faults.splice(foundFaultData.foundItemIndex, 0, foundFaultData.foundItem)
          copiedState.isDirty = false
        }

        copiedState.errors = validateFault(
          updatedFaultData.updated,
          copiedState.errors,
          foundFaultData.foundItem.recommendedActions.length != updatedFaultData.updated.recommendedActions.length
        )
      }
      copiedState.isSubmitting = false

      copiedState.isValid = isFaultFormValid(copiedState.errors)
      return copiedState

    case UPDATE_RECOMMENDED_ACTION:
      const transcribedState = structuredClone(state)
      const fault = transcribedState.faults.find((fault) => getEntityId(fault) === action.payload.faultId)
      if (fault) {
        const foundRecommendedActionData = findAndDelete<RecommendedAction>(
          fault.recommendedActions,
          (recommendedAction) => getEntityId(recommendedAction) === action.payload.id
        )
        if (foundRecommendedActionData) {
          const updatedRecommendedActionData = updateEntity<RecommendedAction>(
            foundRecommendedActionData.foundItem,
            action.payload
          )
          if (updatedRecommendedActionData.hasChanges) {
            fault.recommendedActions.splice(
              foundRecommendedActionData.foundItemIndex,
              0,
              updatedRecommendedActionData.updated
            )
            transcribedState.errors = validateRecommendedAction(
              updatedRecommendedActionData.updated,
              fault,
              transcribedState.errors
            )

            transcribedState.isDirty = !isEqual(
              removeKeysRecursively(transcribedState.faults, ['state', 'faultId', 'evidences']),
              removeKeysRecursively(state.initialStateFaults, ['state', 'faultId', 'evidences'])
            )
          } else {
            fault.recommendedActions.splice(
              foundRecommendedActionData.foundItemIndex,
              0,
              foundRecommendedActionData.foundItem
            )
            transcribedState.isDirty = false
          }
        }
      }
      transcribedState.isSubmitting = false
      transcribedState.isValid = isFaultFormValid(transcribedState.errors)

      return transcribedState

    case ADD_EVIDENCE_UPLOADED_IMAGE:
      const stateToUpdate = structuredClone(state)
      const faultToUpdate = stateToUpdate.faults.find((fault) => getEntityId(fault) === action.payload.faultId)
      if (faultToUpdate) {
        faultToUpdate.evidences = action.payload.evidences
      }
      return stateToUpdate

    case UPDATE_EVIDENCE_IMAGE_DATA:
      const stateToAlter = structuredClone(state)
      const faultToAlter = stateToAlter.faults.find((fault) => getEntityId(fault) === action.payload.appInfo.nodeID)
      if (faultToAlter) {
        faultToAlter.evidences = [...faultToAlter.evidences].map((evidence) => {
          if (evidence.imageInfo.uniqueId == action.payload.imageInfo.uniqueId) {
            return updateEntity(evidence, action.payload).updated
          }
          return evidence
        })
      }
      stateToAlter.isDirty = !isEqual(
        removeKeysRecursively(stateToAlter.faults, ['state', 'faultId', 'fileName']),
        removeKeysRecursively(state.initialStateFaults, ['state', 'faultId', 'fileName'])
      )
      return stateToAlter

    case REMOVE_EVIDENCE_UPLOADED_IMAGE:
      const stateToModify = structuredClone(state)
      const faultToModify = stateToModify.faults.find((fault) => getEntityId(fault) === action.payload.faultId)
      if (faultToModify && faultToModify.evidences) {
        findAndDelete(faultToModify.evidences, (evidence) => evidence.imageInfo.fileID == action.payload.fileId)
      }
      stateToModify.isDirty = !isEqual(
        removeKeysRecursively(stateToModify.faults, ['state', 'faultId']),
        removeKeysRecursively(state.initialStateFaults, ['state', 'faultId'])
      )
      return stateToModify
    default:
      return state
  }
}

export { faultsFormReducer }
