import { useDashboardContext } from '@/contexts/dashboard'
import useWidgetConditionalStatuses from '@/modules/dashboard/hooks/widgets/useWidgetConditionStatuses'
import useWidgetFaults from '@/modules/dashboard/hooks/widgets/useWidgetFaults'
import useWidgetRecommendedActions from '@/modules/dashboard/hooks/widgets/useWidgetRecommendedActions'
import useRecommendedActionsDueDatesStatus from './useRecommendedActionsDueDatesStatus'
import { ENV } from '@/shared/constants'

const useWidgets = () => {
  const { dashboardFilters } = useDashboardContext()
  const widgetConditionalStatuses = useWidgetConditionalStatuses(dashboardFilters) //Asset Health Condition
  const widgetRecommended = useWidgetRecommendedActions(dashboardFilters) //Recommended Actions
  const recommendedActionsDueDatesStatus = useRecommendedActionsDueDatesStatus(dashboardFilters) //Recommended Actions Due Dates Status
  const widgetFaults = useWidgetFaults(dashboardFilters) //Open Faults
  const baseWidgetsExecutionData = [
    {
      fetchFn: widgetConditionalStatuses.execute,
      fetchFnStatus: widgetConditionalStatuses.status,
    },
    {
      fetchFn: widgetRecommended.execute,
      fetchFnStatus: widgetRecommended.status,
    },
    {
      fetchFn: widgetFaults.execute,
      fetchFnStatus: widgetFaults.status,
    },
  ]

  const widgetsExecutionFunctionsData =
    import.meta.env.MODE === ENV.DEV || import.meta.env.MODE === ENV.BUILD_DEV || import.meta.env.MODE === ENV.QA
      ? [
          ...baseWidgetsExecutionData,
          {
            fetchFn: recommendedActionsDueDatesStatus.execute,
            fetchFnStatus: recommendedActionsDueDatesStatus.status,
          },
        ]
      : baseWidgetsExecutionData

  return {
    widgetConditionalStatuses,
    widgetRecommended,
    recommendedActionsDueDatesStatus,
    widgetsExecutionFunctionsData,
    widgetFaults,
  }
}

export default useWidgets
