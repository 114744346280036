import { FC, useMemo } from 'react'
import { Slice, YearMonth } from '@/models/widgets/asset-data-collection/types'
import { ConditionStatusType } from '@/models/widgets/asset-health-condition/types'
import AssetHealthConditionPieChart from '@/modules/dashboard/components/widgets/asset-health-condition/ahc-current-month-status/AssetHealthConditionPieChart'
import { dataTestId } from '@/tests/testid'
import { StyledAHCCurrentMonthStatusContainer, StyledAHCCurrentMonthStatusContent } from '../styled'
import AHCContentRow from './currentmoth-content-row/AHCContentRow'

interface AHCCurrentMonthStatusProps {
  slice: Slice<ConditionStatusType, YearMonth>
  showPercentages: boolean
}

const AssetHealthConditionCurrentMonthStatus: FC<AHCCurrentMonthStatusProps> = ({ slice, showPercentages }) => {
  const data = useMemo(() => {
    return Object.entries(slice.data).map(([name, value]) => ({ name, value }))
  }, [slice.data])

  return (
    <StyledAHCCurrentMonthStatusContainer data-testid={dataTestId.assetHealthConditionCurrentMonthStatus}>
      <h4>Current Month Status</h4>
      <StyledAHCCurrentMonthStatusContent>
        <div>
          {data.map((item) => (
            <AHCContentRow name={item.name} value={item.value} showPercentages={showPercentages} />
          ))}
        </div>
        <AssetHealthConditionPieChart
          data={data}
          specialLabelIndex={1}
          specialLabel="Special Category"
          showPercentages={showPercentages}
        />
      </StyledAHCCurrentMonthStatusContent>
    </StyledAHCCurrentMonthStatusContainer>
  )
}

export default AssetHealthConditionCurrentMonthStatus
