import { COLORS, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledTableContainer = styled.div<{ bottomMargin: string }>`
  overflow: auto;
  position: relative;
  max-height: ${({ bottomMargin }) => `calc(100vh - ${bottomMargin})`};
`

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  tbody tr {
    width: 100%;
    height: 2.875rem;
  }

  th {
    text-align: left;
    user-select: none;
    white-space: nowrap;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`
export const StyledTablePaddingData = styled.td<{ height: number }>`
  height: ${({ height }) => `${height}px`};
`
