import { DarwinWidgetError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useDashboardContext } from '@/contexts/dashboard'
import { WidgetApiToExecute, WidgetType } from '@/models/dashboard/types'
import useWidgets from '@/modules/dashboard/hooks/widgets/useWidgetsHook'
import { ERR_CANCELED } from '@/shared/constants'
import { LoadingType } from '@/shared/hooks/useApi'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepDebouncedMemo from '@/shared/hooks/useDeepDebouncedMemo'
import useTimeout from '@/shared/hooks/useTimeout'
import { FC, useRef } from 'react'

interface WidgetErrorSilentRetryProps {
  widgetErrors: DarwinWidgetError[]
  retryCount?: number
}
/**
 * A React component that silently retries specific widget errors a certain number of times.
 *
 * This component manages automatic retries for widget errors without user interaction.
 * It tracks the retry count for each error and only attempts retries within the
 * specified `retryCount` limit.
 *
 * @param {WidgetErrorSilentRetryProps} props - Component properties.
 * @returns {JSX.Element} An empty JSX element (renders nothing).
 */
const WidgetErrorSilentRetry: FC<WidgetErrorSilentRetryProps> = ({ retryCount = 2, widgetErrors }) => {
  const errorsToRetry = useRef<Partial<Record<WidgetType, WidgetApiToExecute>>>({})
  const { recommendedActionsDueDatesStatus, widgetConditionalStatuses, widgetFaults, widgetRecommended } = useWidgets()

  const { setWidgetErrors } = useDashboardContext()

  const updateErrorToRetry = (widgetType: WidgetType, retryFn: WidgetApiToExecute['retryFn']) => {
    //If we do not have this widget in our retry object than add it and set count to 1
    if (!(widgetType in errorsToRetry.current)) {
      errorsToRetry.current = { ...errorsToRetry.current, [widgetType]: { retryFn, retryCount: 1 } }
      //Otherwise increase count by 1 for existence entry
    } else if (errorsToRetry.current[widgetType]) {
      const currentRetryCount = errorsToRetry.current[widgetType]!.retryCount
      errorsToRetry.current = {
        ...errorsToRetry.current,
        [widgetType]: { retryFn, retryCount: currentRetryCount + 1 },
      }
    }
  }

  /**
   * While each error might be technically different, we want to avoid re-rendering the component unnecessarily.
   * Deep comparison, which relies on the toJSON property, might incorrectly identify errors as distinct even if they are essentially the same.
   * To address this, we'll remove the toJSON property and use a memoization technique to store the error array.
   * This will ensure that the component only re-renders when there's a genuine change in the error state.
   */
  const cleanUpErrors = useDeepDebouncedMemo(
    () => {
      let cleanedErrors: Omit<DarwinWidgetError, 'toJSON'>[] = []
      if (widgetErrors.length > 0) {
        // eslint-disable-next-line unused-imports/no-unused-vars
        cleanedErrors = widgetErrors.map(({ toJSON, ...rest }) => rest)
        if (Object.keys(errorsToRetry.current).length !== 0) {
          cleanedErrors = [...widgetErrors].reverse()
        }
      }
      return cleanedErrors
    },
    [widgetErrors],
    1000
  )

  const executeRetries = useDeepCompareCallback(async () => {
    const fnsToExecutes = Object.entries(errorsToRetry.current)
      //We filter out items with already exceeded retry count and widget that are not in error state
      .filter(
        ([key, item]) =>
          item.retryCount <= retryCount && cleanUpErrors.some((widgetError) => widgetError.widgetType === key)
      )
      .map(([_, item]) => item.retryFn({ loaderType: LoadingType.NONE }))
    //if we have function to retry than call promise settled for them
    if (fnsToExecutes.length > 0) {
      const promises = await Promise.allSettled(fnsToExecutes)

      const errors = promises.filter(
        (promise): promise is PromiseRejectedResult =>
          promise.status === 'rejected' && promise.reason.code !== ERR_CANCELED
      )
      //In case of errors, update context with new values
      if (errors.length > 0) {
        setWidgetErrors(errors.map((error) => error.reason as DarwinWidgetError))
      }
    }
  }, [cleanUpErrors])

  useDeepCompareEffect(() => {
    if (cleanUpErrors.length > 0) {
      cleanUpErrors.forEach((error) => {
        switch (error.widgetType) {
          case 'asset-health-condition':
            updateErrorToRetry(WidgetType.ASSET_HEALTH_CONDITION, widgetConditionalStatuses.execute)
            break
          case 'recommended-actions':
            updateErrorToRetry(WidgetType.RECOMMENDED_ACTIONS, widgetRecommended.execute)
            break
          case 'ra-due-dates-status':
            updateErrorToRetry(WidgetType.RA_DUE_DATES_STATUS, recommendedActionsDueDatesStatus.execute)
            break
          case 'asset-open-faults':
            updateErrorToRetry(WidgetType.ASSET_OPEN_FAULTS, widgetFaults.execute)
            break
        }
      })
    }

    if (Object.keys(errorsToRetry.current).length > 0) {
      startTimeout()
    }
  }, [cleanUpErrors])

  const { startTimeout } = useTimeout(executeRetries, 5000)

  return <></>
}

export default WidgetErrorSilentRetry
