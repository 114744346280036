// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnCell } from '@/common/table/base-components/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PX = 50

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateNumberColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('number', {
    id: 'number',
    header: '',
    cell: ({ getValue }) => getValue(),
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={true}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableNumberColumnCell: FC<{ value: number }> = ({ value }) => (
  <ColumnCell columnWidth={COLUMN_WIDTH_IN_PX} value={deviceNumberFormatter.format(value)} centerValue={true} />
)
