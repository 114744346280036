import { Row } from '@tanstack/react-table'
import dayjs from 'dayjs'

export const DateSortFn = <T>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const valueA = rowA.getValue(columnId) as string
  const valueB = rowB.getValue(columnId) as string

  if (valueA === 'no date available') return -1
  if (valueB === 'no date available') return 1

  if (valueA === '-' && valueB === '-') return 0
  if (valueA === '-') return 1 // Move '-' to the end
  if (valueB === '-') return -1 // Move '-' to the end

  const dateA = dayjs(valueA)
  const dateB = dayjs(valueB)

  if (dateA.isSame(dateB)) return 0

  return dateA.isAfter(dayjs(dateB), 'date') ? 1 : -1
}
