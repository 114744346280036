import { StyledBackButton } from '@/modules/site-details/styled'
import { Button, COLORS } from '@skf-internal/ui-components-react'
import {
  StyledContractDetailsContainer,
  StyledContractDetailsContent,
  StyledSiteDetailsHeading,
  StyledSiteDetailsActionButtonContainer,
} from '@/modules/site-details/styled'
import { dataTestId } from '@/tests/testid'
import ContractDetailsTabs from '@/modules/site-details/components/contract-details/contract-details-tabs'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/shared/constants'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import useUpdateSiteDetails from '@/modules/site-details/hooks/useUpdateSiteDetails'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { LoadingType } from '@/shared/hooks/useApi'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import { useEffect } from 'react'
import { openToast } from '@/store/genericToast/actions'

const ContractDetails = () => {
  const navigate = useNavigate()
  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))
  const siteDetailsDispatch = useAppDispatch()

  const {
    siteDetailsData,
    setIsSiteDetailsEdited,
    setSiteDetailsEditedFormData,
    setLogoURL,
    isSiteDetailsEdited,
    logoURL,
    siteDetailsEditedFormData,
    siteDetailsError,
    setSiteDetailsError,
  } = useSiteDetailsContext()

  const { execute: updateSiteDetails } = useUpdateSiteDetails(siteDetailsEditedFormData as SiteDetailsData)

  const handleBackToOverviewClick = () => {
    navigate(`${ROUTES.OVERVIEW}`)
  }
  const handleCancelClick = () => {
    setSiteDetailsEditedFormData(siteDetailsData)
    setLogoURL(siteDetailsData?.logo?.fileURL)
    setIsSiteDetailsEdited(false)
  }
  const closeDialog = () => {
    siteDetailsDispatch(closeGenericConfirmDialog())
  }
  const handleSaveClick = () => {
    siteDetailsDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Update the site details',
        message: `Update the site details for ${selectedCustomer.name}?`,
        buttons: [
          <GenericConfirmationDialogButton type="secondary" label="Cancel" onClick={() => closeDialog()} />,
          <GenericConfirmationDialogButton
            type="primary"
            label="Confirm"
            onClick={async () => {
              closeDialog()
              await updateSiteDetails({ loaderType: LoadingType.GLOBAL })
            }}
          />,
        ],
      })
    )
  }

  useEffect(() => {
    if (siteDetailsError.updateSiteDetailsError) {
      siteDetailsDispatch(
        openToast({
          children: `Can not update site details of ${selectedCustomer.name}`,
          feSeverity: 'error',
        })
      )
      setSiteDetailsError({ ...siteDetailsError, updateSiteDetailsError: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetailsError])
  return (
    <StyledContractDetailsContainer feFlexDirection="column" data-testid={dataTestId.siteDetailsContractDetails}>
      <StyledContractDetailsContent feFlexDirection="column" feGap="xxl">
        <div>
          <StyledBackButton
            feIcon={{
              feIcon: 'arrowLeft',
              position: 'left',
            }}
            feType="secondary"
            data-testid={dataTestId.siteDetailsBackButton}
            onClick={handleBackToOverviewClick}
            feSize="sm"
            backgroundColor={COLORS.gray050}
          >
            Back to overview
          </StyledBackButton>
        </div>

        <div>
          <StyledSiteDetailsHeading as="h1">Site Details</StyledSiteDetailsHeading>
        </div>
        <ContractDetailsTabs />
      </StyledContractDetailsContent>

      <StyledSiteDetailsActionButtonContainer feJustifyContent="end" feAlignItems="end">
        <Button
          feType="secondary"
          disabled={!isSiteDetailsEdited}
          data-testid={dataTestId.siteDetailsCancelButton}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>

        <Button
          feType="primary"
          disabled={!isSiteDetailsEdited || !logoURL}
          data-testid={dataTestId.siteDetailsSaveButton}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </StyledSiteDetailsActionButtonContainer>
    </StyledContractDetailsContainer>
  )
}

export default ContractDetails
