// =================================================================================================
// IMPORTS
// =================================================================================================

import { css } from '@emotion/react'
import { FC } from 'react'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const ColumnDef: FC<{ columnWidth?: number }> = ({ columnWidth }) => {
  const width = columnWidth ? `${columnWidth}px;` : '100%'
  return (
    <col
      span={1}
      css={css`
        width: ${width};
      `}
    ></col>
  )
}
