// =================================================================================================
// UTILS
// =================================================================================================

import { Cell, RowModel } from '@tanstack/react-table'
import OverAllTableAutoDiagnosticsColumnCell from './columns/auto-diagnostics-column'
import OverAllTableDevicesSensorsColumnCell from './columns/devices-sensors-management-column'
import OverAllTableUnresolvedRecommendationsColumnCell from './columns/unresolved-recommendations-column'
import { FC, Fragment } from 'react'
import { VirtualItem } from '@tanstack/react-virtual'
import { ColumnId } from './table'
// import { OverAllTableComplianceColumnCell } from './columns/compliance-column'
import OverAllTableCompanyColumnCell from './columns/company-column'
import { OverviewDataRow } from '@/models/overview/types'
import { StyledTableRow } from '@/modules/overview/styled'
import OverAllTableAnalysisBacklogColumnCell from '@/modules/overview/components/overview-table/columns/analysis-backlog-column'
import OverAllTableActionsCell from '@/modules/overview/components/overview-table/columns/actions-column'

const dataColumnRecord: Record<ColumnId, (cell: Cell<OverviewDataRow, unknown>) => JSX.Element> = {
  company: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableCompanyColumnCell companyId={cell.row.original.companyID} />
  ),
  // compliance: (cell: Cell<OverviewDataRow, unknown>) => (
  //   <OverAllTableComplianceColumnCell value={cell.getValue<OverviewDataRow['compliance']>()} />
  // ),
  analysisBacklog: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableAnalysisBacklogColumnCell
      value={cell.getValue<OverviewDataRow['analysisBacklog']>()}
      companyId={cell.row.original.companyID}
    />
  ),
  autoDiagnostics: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableAutoDiagnosticsColumnCell value={cell.getValue<string>()} companyId={cell.row.original.companyID} />
  ),
  deviceAndSensors: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableDevicesSensorsColumnCell
      value={cell.getValue<OverviewDataRow['deviceAndSensors']>()}
      companyId={cell.row.original.companyID}
    />
  ),
  unresolvedRecommendations: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableUnresolvedRecommendationsColumnCell
      value={cell.getValue<OverviewDataRow['unresolvedRecommendations']>()}
      companyId={cell.row.original.companyID}
    />
  ),
  actions: (cell: Cell<OverviewDataRow, unknown>) => (
    <OverAllTableActionsCell companyId={cell.row.original.companyID} />
  ),
}

// =================================================================================================
// COMPONENT
// =================================================================================================

export const TableData: FC<{
  tableRowModel: RowModel<OverviewDataRow>
  items: VirtualItem[]
}> = ({ tableRowModel, items }) => {
  return items.map((virtualRow) => {
    const row = tableRowModel.rows[virtualRow.index]

    return (
      <Fragment key={`${virtualRow.key}-device`}>
        <StyledTableRow data-index={virtualRow.index}>
          {row.getVisibleCells().map((cell) => {
            const columnId: ColumnId = cell.column.id! as ColumnId
            const ColDefComponent = () => dataColumnRecord[columnId](cell)
            return <ColDefComponent key={cell.id} />
          })}
        </StyledTableRow>
      </Fragment>
    )
  })
}

// =================================================================================================
// STYLED COMPONENTS
// =================================================================================================
