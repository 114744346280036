import { fetchFaultEvidenceImage } from '@/api/paths/documents/files'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { useApi } from '@/shared/hooks/useApi'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'

const useFetchFaultEvidenceImage = (imageRequestData: EvidenceImageData) => {
  const fetchFaultEvidenceImageDispatch = useAppDispatch()

  return useApi(() => {
    return fetchFaultEvidenceImage(
      { imageUrl: imageRequestData.imageInfo.fileURL },
      {
        onError: async () => {
          fetchFaultEvidenceImageDispatch(
            openToast({
              children: 'Image download failed, please try again later',
              feSeverity: 'error',
            })
          )
        },
      }
    )
  })
}

export default useFetchFaultEvidenceImage
