import { FilterContentProps } from '@/shared/components/GenericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { TextField } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const TextFilterContent: FC<FilterContentProps<string>> = ({ initialValue, onFilterValueChange }) => {
  return (
    <TextField
      feSize="sm"
      feLabel="Search"
      feHideLabel
      placeholder="Search"
      defaultValue={initialValue}
      onChange={(_, searchString) => onFilterValueChange(searchString)}
      autoFocus
      data-testid={dataTestId.textFilterContent}
    />
  )
}
export default TextFilterContent
