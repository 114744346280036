// =================================================================================================
// IMPORTS
// =================================================================================================

import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { exportDevicesAndSensorsDataToCSV } from '@/modules/devices-sensors/utils'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// COMPONENT
// =================================================================================================

interface DevicesSensorsHeaderProps {
  table: Table<DevicesSensorsTableDeviceRowData>
  filters: ColumnFiltersState
}

export const DevicesSensorsHeader: FC<DevicesSensorsHeaderProps> = ({ table, filters }) => {
  const devicesAndSensorsDispatch = useAppDispatch()
  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))

  const {
    devicesAndSensorsTableData: { columnFilters, sorting },
    setDevicesAndSensorsTableData,
  } = useDevicesAndSensorsContext()

  const isTableFiltered = columnFilters && columnFilters.length > 0
  const isTableSorted = sorting && sorting.length > 0

  const shouldClearFilters = !(isTableFiltered || isTableSorted)
  const handleClearFilters = () => setDevicesAndSensorsTableData({ sorting: [], columnFilters: [] })

  useDeepCompareEffect(() => {
    devicesAndSensorsDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        title: 'Devices and Sensors',
        showSubtitle: true,
        subtitle: 'IMX-8 / 16 Information',
        showInfoPanel: false,
        showActionPanel: true,
      })
    )
    devicesAndSensorsDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withClearFiltersButton: true,
        isClearFiltersButtonDisabled: shouldClearFilters,
        clearFiltersButtonFn: handleClearFilters,
        withRefreshButton: true,
        withCSVDownloadButton: true,
      })
    )
  }, [table, shouldClearFilters])

  useDeepCompareEffect(() => {
    const filteredRows = table.getFilteredRowModel().rows

    if (filteredRows.length > 0) {
      devicesAndSensorsDispatch(
        setModuleHeaderCSVData({
          csvData: exportDevicesAndSensorsDataToCSV(
            filteredRows.map((row) => {
              const rowData = row.original || { channels: [] }
              const channels = rowData.channels || []
              const statusFilter = filters.find((f) => f.id === 'status')?.value as string[]

              const filteredChannels = statusFilter
                ? channels.filter((c) => statusFilter.some((f) => f === c.status))
                : channels

              const rowWithFilteredChannels = structuredClone(rowData)
              rowWithFilteredChannels.channels = filteredChannels
              return rowWithFilteredChannels
            }),
            selectedCustomer.name,
            filters
          ),
          moduleName: 'Devices and Sensors',
        })
      )
    }
  }, [table.getFilteredRowModel().rows, selectedCustomer, filters])

  return <ModuleHeader />
}
