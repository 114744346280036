// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnDef } from '@/common/table/base-components/column-def'
import { OverviewDataRow } from '@/models/overview/types'
import { StyledEmptySpaceHeader, StyledOverviewCompanyColumn } from '@/modules/overview/styled'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateCompanyColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('company', {
    id: 'company',
    header: 'Company',
    /* v8 ignore next 1 */
    cell: ({ getValue }) => <div>{getValue()}</div>,
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableCompanyColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableCompanyHeaderColumn: FC<{ header: Header<OverviewDataRow, unknown> }> = ({ header }) => (
  <TableHeaderColumn<OverviewDataRow> header={header} centerValue={true} withSorting={false}>
    <StyledEmptySpaceHeader className="sub-header" />
  </TableHeaderColumn>
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

const OverAllTableCompanyColumnCell: FC<{ companyId: string }> = ({ companyId }) => {
  const { customersList } = useTypedSelector((state) => ({ ...state }))
  const company = customersList.find((customer) => customer.id === companyId)
  return (
    <td data-testid={dataTestId.overviewCompanyColumn}>
      <StyledOverviewCompanyColumn>{company?.name}</StyledOverviewCompanyColumn>
    </td>
  )
}
export default OverAllTableCompanyColumnCell
