import { ColumnCell } from '@/common/table/base-components/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { DevicesSensorsTableDeviceRowData, UniqueChannelsAssets } from '@/models/devicesAndSensors/types'
import { filterRegistry } from '@/shared/components/GenericTableFilter/constants'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

const COLUMN_WIDTH_IN_PX = 300

export const generateConnectedAssetColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('uniqueChannelsAssets', {
    id: 'uniqueChannelsAssets',
    header: 'Connected Assets',
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    cell: ({ getValue }) => getValue().map((value) => value.name),
    meta: {
      filterType: 'text',
    },
  })

export const DevicesSensorsTableConnectedAssetColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />

export const DevicesSensorsTableConnectedAssetHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={false}
  />
)

export const DevicesSensorsTableConnectedAssetColumnCell: FC<{ value: Array<UniqueChannelsAssets> }> = ({ value }) => (
  <>
    {value && value.length > 1 ? (
      <ColumnCell columnWidth={COLUMN_WIDTH_IN_PX} value={`${value.length} assets connected`} centerValue={false} />
    ) : value && value.length > 0 ? (
      value.map((val) => <ColumnCell columnWidth={COLUMN_WIDTH_IN_PX} value={val.name} centerValue={false} />)
    ) : (
      <ColumnCell columnWidth={COLUMN_WIDTH_IN_PX} value="-" centerValue={false} />
    )}
  </>
)
