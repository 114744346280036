import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { REMOVE_EVIDENCE_UPLOADED_IMAGE } from '@/modules/report-status/reducer/actions.types'
import { useApi } from '@/shared/hooks/useApi'

const useDeleteFaultImage = (fileId: string) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()
  const { faultId } = useFaultEvidenceContext()

  const deleteEvidenceImage = async () => {
    faultsFormDispatch({ type: REMOVE_EVIDENCE_UPLOADED_IMAGE, payload: { faultId, fileId } })
  }

  return useApi(() => {
    return deleteEvidenceImage()
  })
}

export default useDeleteFaultImage
