export type MetaTableState = {
  tableState: boolean
}

export enum AssetStatus {
  'never-reported' = 'Never reported',
  normal = 'Normal',
  acceptable = 'Acceptable',
  unsatisfactory = 'Unsatisfactory',
  unacceptable = 'Unacceptable',
  severe = 'Severe',
}

export type AssetStatusString = keyof typeof AssetStatus

export type TupleUnion<U extends string, R extends string[] = []> = {
  [S in U]: Exclude<U, S> extends never ? [...R, S] : TupleUnion<Exclude<U, S>, [...R, S]>
}[U]

export type AllKeysOf<T> = keyof T extends string ? TupleUnion<keyof T> : never

export type Require<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
