// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'
import { ToolTipOverviewDashboard } from '../tooltip'
import { OverviewDataRow } from '@/models/overview/types'
import { ColumnDef } from '@/common/table/base-components/column-def'
import SubHeader from '@/modules/overview/components/overview-table/subHeader'
import { StyledOverviewUnresolvedRecommendationsColumn } from '@/modules/overview/styled'
import useRedirectToModuleWithSelectedCustomer from '@/modules/overview/hooks/useRedirectToModuleWithSelectedCustomer'
import { dataTestId } from '@/tests/testid'
import { ROUTES } from '@/shared/constants'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'

// =================================================================================================
// UTILS
// ===============

const tooltipText: Record<string, string> = {
  open: 'Count of open recommended actions',
  overdue: 'Count of overdue recommended actions',
}

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateUnresolvedRecommendationsColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('unresolvedRecommendations', {
    id: 'unresolvedRecommendations',
    header: 'Open Recommendations',
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableUnresolvedRecommendationsColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableUnresolvedRecommendationsColumn: FC<{
  header: Header<OverviewDataRow, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<OverviewDataRow> header={header} centerValue={true} withSorting={false}>
    <SubHeader titles={['Open', 'Overdue']} gridProp="1fr 1fr" />
  </TableHeaderColumn>
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================
interface OverAllTableUnresolvedRecommendationsColumnCellProps {
  value: OverviewDataRow['unresolvedRecommendations']
  companyId: string
}
const OverAllTableUnresolvedRecommendationsColumnCell: FC<OverAllTableUnresolvedRecommendationsColumnCellProps> = ({
  value,
  companyId,
}) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  return (
    <td>
      <StyledOverviewUnresolvedRecommendationsColumn>
        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['open']}>
            <a
              data-testid={dataTestId.overviewUnresolvedRecommendationsColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.UNRESOLVED_RECOMMENDATION, companyId)}
              className="cell-value"
            >
              {value.open}
            </a>
          </ToolTipOverviewDashboard>
        </div>
        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['overdue']}>
            <a
              data-testid={dataTestId.overviewUnresolvedRecommendationsColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.UNRESOLVED_RECOMMENDATION, companyId)}
              className="cell-value"
            >
              {value.overdue}
            </a>
          </ToolTipOverviewDashboard>
        </div>
      </StyledOverviewUnresolvedRecommendationsColumn>
    </td>
  )
}
export default OverAllTableUnresolvedRecommendationsColumnCell
