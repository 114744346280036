import { WidgetType } from '@/models/dashboard/types'
import { AxiosError } from 'axios'

export class DarwinError extends AxiosError {
  isInTabMode: boolean
  constructor(error: AxiosError) {
    super(error.message, error.code)
    this.status = error.response?.status || error.status
    this.isInTabMode = true
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinWidgetError extends DarwinError {
  isWidgetError: boolean
  widgetType: WidgetType

  constructor(error: AxiosError, widgetType: WidgetType) {
    super(error)
    this.status = error.response?.status || error.status
    this.isWidgetError = true
    this.isInTabMode = false
    this.widgetType = widgetType
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinDetachedModuleError extends DarwinError {
  isInDetachedMode: boolean
  constructor(error: AxiosError) {
    super(error)
    this.status = error.response?.status || error.status
    this.isInTabMode = false
    this.isInDetachedMode = true
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinNotFoundError extends AxiosError {
  isInTabMode: boolean
  constructor(error: AxiosError) {
    super(error.message, error.code)
    this.status = error.response?.status || error.status
    this.isInTabMode = false
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinErrorDialog extends DarwinError {
  isDialogError: boolean
  constructor(error: AxiosError) {
    super(error)
    this.isDialogError = true
    this.toJSON = () => ({
      ...this,
    })
  }
}

export type DarwinErrorType = typeof DarwinError
